.white-bg {
  background-color: white
}

.copy-button-center {
  margin-left: 50%
}

.center {
  margin-left: 50%;
  margin-top: 300px;
}
.copy-button-all {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 15px;
}

.copy-table-border {
  margin: 10px 0px;
}

.copy-button-primary {
  background-color: rgb(33,150,243);
  color: white;
  padding: 10px 10px;
  letter-spacing: .010em;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

.copy-button-reject {
  background-color: rgb(255,82,82);
  color: white;
  padding: 10px 10px;
  letter-spacing: .010em;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

.app-button {
  background: '#366CD9';
  color: '#FFFFFF';
  font-weight: 400;
  font-size: 14;
  font-family: 'Roboto';
  text-transform: 'capitalize';
  margin: 10;
}

.itemDesc div textarea:disabled {
  color: #666666;
}

.saveFilterTitle {
  background-color: rgba(25, 117, 211, 1);
}

.filterTitle {
  font-family: 'Roboto', 'Helvetica-Light', 'Helvetica Light', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  font-style: normal;
  font-size: 8px;
  color: #FFFFFF;
}

.react-daterange-picker__range-divider {
  margin: 5px 5px 0px 5px;
}

.buldUploadWrapper{
  position: relative;
}


.alignCenterCircularBar{
text-align: center;
}



.genAiIndicator{
  position: relative;
  max-width: 400px;
  overflow-wrap: break-word;
  padding: 10px;
}


.genAiNotPresent{
  max-width: 400px;
  overflow-wrap: break-word;
  padding: 10px;
}