html {
  overflow-y: scroll;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
}


.customeBadgeColour span {
  background-color: #6200ea;
  color: white;
}

.ribbon-wrapper {
	position: relative;z-index:998;
}
  .ribbon-front {
	background-color: #6200ea;	height: 25px;
	position: relative;
	z-index: 2; font:15px/25px bold Verdana, Geneva, sans-serif; color:#f8f8f8; text-align:center;text-shadow: 0px 1px 2px #558b2f;
}

  .ribbon-front,
  .ribbon-back-left,
  .ribbon-back-right
{
	-moz-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-khtml-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-webkit-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
	-o-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
}

  .ribbon-edge-topleft,
  .ribbon-edge-topright,
  .ribbon-edge-bottomleft,
  .ribbon-edge-bottomright {
	position: absolute;
	z-index: 1;
	border-style:solid;
	height:0px;
	width:0px;
}


  .ribbon-edge-bottomleft,
  .ribbon-edge-bottomright {
	top: 25px;
}

  .ribbon-edge-topleft,
  .ribbon-edge-bottomleft {
	border-color: transparent #4527a0 transparent transparent;
}

  .ribbon-edge-topleft {
	top: -5px;
	border-width: 5px 10px 0 0;
}
  .ribbon-edge-bottomleft {
	border-width: 0 10px 0px 0;
}

  .ribbon-edge-topright,
  .ribbon-edge-bottomright {
	left: 140px;
	border-color: transparent transparent transparent #4527a0;
}

  .ribbon-edge-topright {
	top: 0px;
	border-width: 0px 0 0 10px;
}
  .ribbon-edge-bottomright {
	border-width: 0 0 5px 10px;
}


@-webkit-keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}
@keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}

.reuseAnimationEffect{
	background: rgb(255,255,255);
	width:40px;
	height:100%;
	z-index:999;
	position:absolute;
   -webkit-animation: flow 1.5s linear infinite;
   -moz-animation: flow 1.5s linear infinite;
   -webkit-transform: skew(20deg);
	-moz-transform: skew(20deg);
	-o-transform: skew(20deg);
   background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1)));
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%);
	border-left:1px solid #fff;
}



.textAnimationForClick {
	font-family: monospace;
	font-size: 18px;
	animation: color-change 1s infinite;
	cursor: pointer;
  }

  .genAiIndicatorText {
	background-image: url('../images/genAiBlueLogo.svg') !important;
	background-repeat: no-repeat !important;
	background-position:right !important;
	background-origin: content-box !important;
	padding-right: 5px;
	position: absolute;
	right: 10px;
	bottom: 2px;
	width: 32px;
	height: 32px;
  }


  .genAiIndicatorHidden{
display: none;
  }

  
  @keyframes color-change {
	0% { color: red; }
	50% { color: blue; }
	100% { color: red; }
  }