.carousel.carousel-slider {
    margin-left: 60px;
}

.carousel .thumbs-wrapper {
    margin: 0;
    max-width: 400px;
}

.carousel .thumbs {
    white-space: unset;
}